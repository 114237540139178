import React from "react"
import { Alert } from "src/ui"

const Index: React.FC = () => {
  return (
    <div className="py-5 px-5">
      <Alert color="warning">
        No content for this page - you must set a redirection to your default
        language
      </Alert>
    </div>
  )
}

export default Index
